var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.head,"items":_vm.data,"search":_vm.search,"mobile-breakpoint":"1200","loading":_vm.tableLoading,"loading-text":"Loading... Please wait","server-items-length":_vm.data_table_options.totalItems,"page":_vm.data_table_options.page,"options":_vm.data_table_options,"footer-props":_vm.data_table_options.footerProps,"hide-default-footer":""},on:{"update:options":function (item) { return _vm.handlePage(item); },"click:row":function (item) { return _vm.$emit('view', item); }},scopedSlots:_vm._u([{key:"item.pay_way",fn:function(ref){
var item = ref.item;
return [(item.pay_way == 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.onlinePay"))+" ")]):_vm._e(),(item.pay_way == 2)?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.huodaofukuan"))+" ")]):_vm._e(),(item.pay_way == 3)?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.cash"))+" ")]):_vm._e(),(item.pay_way == 4)?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.creditCard"))+" ")]):_vm._e(),(item.pay_way == 5)?_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.other"))+" ")]):_vm._e()]}},{key:"item.OrderActions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('view', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.check")))])]),(item.orderStatus > 1 && _vm.permissionCheck('list', 'order_send'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"},on:{"click":function($event){return _vm.$emit('delivery', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.deliveryRecord")))])]):_vm._e()]}},{key:"item.orderStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getOrderStatus(item).color,"dark":""}},[_vm._v(" "+_vm._s(_vm.getOrderStatus(item).text)+" ")])]}},{key:"item.goods_num",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.goods_num))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.number))+" ")]}},{key:"item.trade_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.trade_total))+" ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.note))+" "),(item.note.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more")]):_vm._e()]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkStrLength(item.address))+" "),(item.address.length > 30)?_c('span',{staticClass:"blue--text"},[_vm._v(".....more ")]):_vm._e()]}}],null,true)}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"text-center mt-4"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"4"}},[_c('v-pagination',{attrs:{"length":_vm.data_table_options.totalItems},model:{value:(_vm.data_table_options.page),callback:function ($$v) {_vm.$set(_vm.data_table_options, "page", $$v)},expression:"data_table_options.page"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"1"}},[_c('v-text-field',{staticClass:"noUnderline",attrs:{"readonly":"","label":"Total Pages","value":_vm.data_table_options.totalItems,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"min":"1","label":"Jump to page","type":"number","hint":"Press Enter or Unfocus to Confirm"},on:{"focus":function($event){return $event.target.select()},"change":_vm.handleGo2Page}})],1)],1)],1):_c('div',{staticClass:"text-center mt-4"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"8"}},[_c('v-pagination',{attrs:{"length":_vm.data_table_options.totalItems},model:{value:(_vm.data_table_options.page),callback:function ($$v) {_vm.$set(_vm.data_table_options, "page", $$v)},expression:"data_table_options.page"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"noUnderline",attrs:{"readonly":"","label":"Total Pages","value":_vm.data_table_options.totalItems,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"min":"1","label":"Jump to page","type":"number","hint":"Press Enter or Unfocus to Confirm"},on:{"focus":function($event){return $event.target.select()},"change":_vm.handleGo2Page}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }