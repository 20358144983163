<template>
  <div>
    <v-data-table
      :headers="head"
      :items="data"
      :search="search"
      mobile-breakpoint="1200"
      :loading="tableLoading"
      loading-text="Loading... Please wait"
      :server-items-length="data_table_options.totalItems"
      :page="data_table_options.page"
      :options="data_table_options"
      :footer-props="data_table_options.footerProps"
      hide-default-footer
      @update:options="(item) => handlePage(item)"
      @click:row="(item) => $emit('view', item)"
    >
      <template v-slot:[`item.pay_way`]="{ item }">
        <span v-if="item.pay_way == 1">
          {{ $vuetify.lang.t("$vuetify.onlinePay") }}
        </span>
        <span v-if="item.pay_way == 2">
          {{ $vuetify.lang.t("$vuetify.huodaofukuan") }}
        </span>
        <span v-if="item.pay_way == 3">
          {{ $vuetify.lang.t("$vuetify.cash") }}
        </span>
        <span v-if="item.pay_way == 4">
          {{ $vuetify.lang.t("$vuetify.creditCard") }}
        </span>
        <span v-if="item.pay_way == 5">
          {{ $vuetify.lang.t("$vuetify.other") }}
        </span>
      </template>

      <template v-slot:[`item.OrderActions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="green"
              @click.stop="$emit('view', item)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-eye
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.check") }}</span>
        </v-tooltip>

        <v-tooltip
          top
          v-if="item.orderStatus > 1 && permissionCheck('list', 'order_send')"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="$emit('delivery', item)"
              color="blue"
              v-bind="attrs"
              v-on="on"
            >
              mdi-file-document
            </v-icon>
          </template>
          <span>{{ $vuetify.lang.t("$vuetify.deliveryRecord") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.orderStatus`]="{ item }">
        <v-chip :color="getOrderStatus(item).color" dark>
          {{ getOrderStatus(item).text }}
        </v-chip>
      </template>

      <template v-slot:[`item.goods_num`]="{ item }">
        {{ formatNumber(item.goods_num) }}
      </template>

      <template v-slot:[`item.number`]="{ item }">
        {{ formatNumber(item.number) }}
      </template>

      <template v-slot:[`item.trade_total`]="{ item }">
        {{ formatNumber(item.trade_total) }}
      </template>

      <template v-slot:[`item.note`]="{ item }">
        {{ checkStrLength(item.note) }}
        <span class="blue--text" v-if="item.note.length > 30">.....more</span>
      </template>

      <template v-slot:[`item.address`]="{ item }">
        {{ checkStrLength(item.address) }}
        <span class="blue--text" v-if="item.address.length > 30"
          >.....more
        </span>
      </template>
    </v-data-table>

    <div class="text-center mt-4" v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-spacer />

        <v-col cols="4" class="mt-2">
          <v-pagination
            v-model="data_table_options.page"
            :length="data_table_options.totalItems"
          />
        </v-col>

        <v-spacer />

        <v-col cols="1" class="d-flex justify-center align-center">
          <v-text-field
            readonly
            label="Total Pages"
            :value="data_table_options.totalItems"
            class="noUnderline"
            disabled
          />
        </v-col>

        <v-col cols="2">
          <v-text-field
            min="1"
            label="Jump to page"
            type="number"
            hint="Press Enter or Unfocus to Confirm"
            @focus="$event.target.select()"
            @change="handleGo2Page"
          />
        </v-col>
      </v-row>
    </div>

    <div class="text-center mt-4" v-else>
      <v-row>
        <v-spacer />

        <v-col cols="8" class="mt-2">
          <v-pagination
            v-model="data_table_options.page"
            :length="data_table_options.totalItems"
          />
        </v-col>

        <v-spacer />

        <v-col cols="6" class="d-flex justify-center align-center">
          <v-text-field
            readonly
            label="Total Pages"
            :value="data_table_options.totalItems"
            class="noUnderline"
            disabled
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            min="1"
            label="Jump to page"
            type="number"
            hint="Press Enter or Unfocus to Confirm"
            @focus="$event.target.select()"
            @change="handleGo2Page"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import serverConfig from "@/utils/serverConfig";

export default {
  name: "OrderListTable",
  props: {
    head: Array,
    data: Array,
    dialog: Boolean,
    search: String,
    tableLoading: Boolean,
    data_table_options: Object,
  },
  methods: {
    handlePage(item) {
      this.$emit("pageChange", item);
    },
    handleGo2Page(item) {
      this.$emit("go2Page", Number(item));
    },
    getOrderStatus(item) {
      const status = {
        1: { text: "Place Order", color: "green" },
        0: { text: "Place Order", color: "green" },
        2: { text: "To be Grouped", color: "orange" },
        3: { text: "Paid", color: "blue" },
        6: { text: "Shipped", color: "purple" },
        7: { text: "Out of Stock", color: "red" },
        "-1": { text: "Cancel", color: "darkblue" },
      };

      return status[item.orderStatus];
    },
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
};
</script>

<style lang="sass">
.pagination-navigation-width
  width: 40px

.noUnderline > .v-input__control > .v-input__slot:before
  border-style: none
</style>